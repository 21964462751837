import React, { Component } from "react"
import { Box, Button, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography, IconButton } from "@coolblue-development/becky"
import {
  Route,
  Routes,
  NavLink,
  HashRouter
} from "react-router-dom";


export default function MetricsBinary() {
    return (
    <Box >
      <Grid container>
        <Grid item xs={3}>
          <Box>
            <Button component={NavLink} to="/CustomMetric" >Add a custom metric</Button>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
                <Button component={NavLink} to="/StandardMetric">Add a standard metric</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
    )
}