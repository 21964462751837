import martinaTheme from './theme';
import { beckyTheme, ThemeProvider } from '@coolblue-development/themes';
import merge from 'lodash/merge';

interface IProps {
  children: React.ReactNode;
}

export default function CoolblueThemeProvider({ children }: IProps) {
  // We are currently not using the typography or spacing from Becky, see:
  //    DADE-1539 use the spacing from Becky
  //    DADE-1542 use the typography from Becky
  const { typography, spacing, ...strippedBeckyTheme } = beckyTheme;
  const theme = merge({}, martinaTheme, strippedBeckyTheme);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}