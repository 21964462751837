import React, { Component } from "react"
import { Box, Button, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography, IconButton } from "@coolblue-development/becky"
import MetricsBinary from "./MetricsBinary";
import MetricsContinuous from "./MetricsContinuous"
import MetricsList from "./MetricsList"
import {
  Route,
  Routes,
  NavLink,
  HashRouter
} from "react-router-dom";

const Components = {
  MetricsBinary,
  MetricsContinuous
};

export const MetricsContainer = (props) => {
    let Component = Components[props.type];
    return (
    <Box>
        <MetricsList />
        <Box >
          <Grid container>
            <Grid item xs={3}>
              <Box>
                <Button component={NavLink} to="/CustomMetric" >Add a custom metric</Button>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                    <Button component={NavLink} to="/StandardMetric">Add a standard metric</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
    </Box>
    );
}

export default MetricsContainer;