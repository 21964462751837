import React, { Component } from "react"
import { Box, Button, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography, IconButton } from "@coolblue-development/becky"

export default function MetricsContinuous() {
      const [metrictype, setMetrictype] = React.useState('');

      const handleMetricTypeChange = (event) => {
        setMetrictype(event.target.value);
      };

    return (
    <Box paddingTop={4}>
      <Grid container>
        <Grid item xs={3}>
          <Box>
                <Button preset="callToAction">Banana</Button>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
                <Button preset="callToAction">Apple</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
    )
}