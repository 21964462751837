import React from "react";
//import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import Main from "./Main";
import "./index.css";
import CoolblueThemeProvider from './Theme/CoolblueThemeProvider';
import store from './Redux/store'
import { Provider } from 'react-redux'

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<Provider store={store}><Main/></Provider>)

//ReactDOM.render(
//  <Main/>,
//  document.getElementById("root")
//);