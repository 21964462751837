import React, { Component, useState } from "react";
import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Divider,
	FormControl,
	Grid,
	MenuItem,
	InputLabel,
	Select,
	TextField,
	Typography,
} from "@coolblue-development/becky";
import { Route, Routes, NavLink, HashRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	selectFormdata,
	addDimension,
	updateActiveDimension,
	removeDimension,
} from "../../Form/FormSlice";
import { info } from "../../Form/Info";
import ColumnSelectionHelper from '../../Components/ColumnSelectionHelper'
import { CircleQuestionmark, Cross } from "@coolblue-development/icons";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

export default function AddDimension(activeDimension) {
	const dispatch = useDispatch();
	const [dimensionForm, setDimensionForm] = React.useState({
		activeDimension,
	});
	const formData = useSelector(selectFormdata);
	const dimensionArray = formData.dimensions;
	const [open, setOpen] = useState(false);
	const [content, setContent] = useState("");

	const handleFormChange = (event) => {
		const key = event.target.name;
		const value = event.target.value;
		const newData = dimensionForm;
		newData[key] = value;
		setDimensionForm(newData);
	};

	const handleOpen = (fieldTopic): void => {
		setOpen(true);
		setContent(info[fieldTopic]);
	};
	const handleClose = (): void => {
		setOpen(false);
	};

	function getCorrectDimensionForm() {
		const copyDimensionForm = { ...dimensionForm };
		const activeDimensionCopy = { ...copyDimensionForm.activeDimension };
		const newDict = {};

		Object.keys(activeDimensionCopy).forEach((item) => {
			if (item !== "activeDimensions") {
				newDict[item] = activeDimensionCopy[item];
			}
		});
		Object.keys(copyDimensionForm).forEach((item) => {
			if (item !== "activeDimension") {
				newDict[item] = copyDimensionForm[item];
			}
		});
		return newDict;
	}

	function handleSubmit() {
		const activeDimensionForm = getCorrectDimensionForm();
		const dimension = dimensionArray.find(
			(dimension) =>
				dimension.dimensionAlias === activeDimensionForm.dimensionAlias
		);
		const itemIndex = dimensionArray.indexOf(dimension);
		if (itemIndex === -1) {
			dispatch(addDimension(activeDimensionForm));
		} else {
			dispatch(removeDimension(activeDimensionForm.removeDimension));
			dispatch(addDimension(activeDimensionForm));
		}
		dispatch(updateActiveDimension({}));
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<Box>
					<Typography variant="h2" gutterBottom component="div">
						Add or Edit Custom Dimension
					</Typography>
				</Box>
			</Grid>
			<ColumnSelectionHelper/>
			<Grid item xs={12} paddingBottom={4} paddingTop={4}>
				<Box>
					<TextField
						name="dimensionDefinition"
						label="Dimension definition"
						placeholder="e.g. device.devicecategory"
						defaultValue={activeDimension["dimensionDefinition"]}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => handleOpen("dimensionDefinition")}>
										<CircleQuestionmark />
									</IconButton>{" "}
								</InputAdornment>
							),
						}}
						value={dimensionForm["dimensionDefinition"]}
						onChange={(e) => {
							handleFormChange(e);
						}}
					/>
				</Box>
			</Grid>
			<Grid item xs={2} paddingBottom={4}>
				<Box>
					<TextField
						name="dimensionAlias"
						label="Dimension alias"
						placeholder="e.g. Device"
						defaultValue={activeDimension["dimensionAlias"]}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => handleOpen("dimensionAlias")}>
										<CircleQuestionmark />
									</IconButton>{" "}
								</InputAdornment>
							),
						}}
						value={dimensionForm["dimensionAlias"]}
						onChange={(e) => {
							handleFormChange(e);
						}}
					/>
				</Box>
			</Grid>

			<Grid item xs={12} paddingTop={4} paddingBottom={4}>
				<Box>
					<Button
						component={NavLink}
						to="/"
						preset="callToAction"
						onClick={() => handleSubmit()}
					>
						Submit
					</Button>
				</Box>
			</Grid>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>
					A little help
					<IconButton
						onClick={handleClose}
						preset="text"
						size="small"
						aria-label="Close dialog"
					>
						<Cross />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>{content}</DialogContentText>
				</DialogContent>
			</Dialog>
		</Grid>
	);
}
