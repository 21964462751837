import React, { Component } from "react"
import { Box, Button, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography } from "@coolblue-development/becky"
import DimensionList from "./DimensionList"
import {
  Route,
  Routes,
  NavLink,
  HashRouter
} from "react-router-dom";

export default function Dimensions() {

    return (
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h2" gutterBottom component="div">
              Dimensions
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} paddingBottom={4}>
            <DimensionList />
        </Grid>
        <Grid container>
            <Grid item xs={3}>
                <Box>
                    <Button component={NavLink} to="/CustomDimension">Add a dimension</Button>
                </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                    <Button component={NavLink} to="/StandardDimension">Add a standard dimension</Button>
              </Box>
            </Grid>
        </Grid>
      </Grid>
    )
}