import React, { Component } from "react"
import { Box, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography } from "@coolblue-development/becky"
import MetricsContainer from "./MetricsContainer";
import { useSelector, useDispatch } from 'react-redux'
import {
  selectFormdata,
  updateMetricType
} from '../../Form/FormSlice'

export default function Metrics() {
      const [metrictype, setMetrictype] = React.useState('');
      const formData = useSelector(selectFormdata);
      const dispatch = useDispatch();

      const handleMetricTypeChange = (event) => {
        setMetrictype(event.target.value);
      };

      const testComponentConfiguration = [
      {
        type: 'MetricsBinary'
      },
      {
        type: 'MetricsContinuous'
      }
    ];

    function getMetricType(metrictype) {
        let value = 0;
        if (formData.metricType!=undefined) {
            value=formData.metricType
        }
        else if(metrictype==undefined || metrictype=='') {
            value=0
        }
        else {
            value=metrictype
        }
        return value
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h2" gutterBottom component="div">
              Metrics
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} paddingBottom={4} paddingRight={4}>
          <FormControl fullWidth>
            <InputLabel id="metrictypelabelid">Type of metrics to analyze</InputLabel>
            <Select
              labelId="metrictypelabelid"
              id="metrictypeid"
              defaultValue={formData.metrictype}
              value={getMetricType(metrictype)}
              label="Type of metrics to analyze"

              onChange={(e) => {dispatch(updateMetricType(e.target.value));
              setMetrictype(e.target.value);
              }}>
            >
              <MenuItem value={0}>Binary Metrics</MenuItem>
              <MenuItem value={1}>Continuous Metrics</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} paddingBottom={4}>
            <MetricsContainer
            { ...testComponentConfiguration[getMetricType(metrictype)] }
            />
        </Grid>
      </Grid>
    )
}