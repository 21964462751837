import React from "react";
import {
	Box,
	Button,
	Divider,
	Grid,
	Tab,
	Tabs,
	TabPanel,
} from "@coolblue-development/becky";
import GeneralInformation from "../Components/Generalinformation";
import Login from "../Components/Login";
import ColumnSelectionHelper from "../Components/ColumnSelectionHelper";
import Metrics from "../Components/Metrics/Metrics";
import Dimensions from "../Components/Dimensions/Dimensions";
import SelectedQuery from "../Components/Queries/SelectedQuery";
import CustomerContact from "../Components/CustomerContact/CustomerContact";
import ReturnRatio from "../Components/ReturnRatio/ReturnRatio";
import { useSelector, useDispatch } from "react-redux";
import RunQueries from "../Submit/Submit";
import { selectFormdata } from "../Form/FormSlice";
import { useAuth } from "oidc-react";
import { getAuthConfig } from "../Auth/AuthConfig";

export default function Home() {
	const [testtype, setTesttype] = React.useState("");
	const handleTestTypeChange = (event) => {
		setTesttype(event.target.value);
	};
	const [truncate, setTruncate] = React.useState("");
	const handleTruncateChange = (event) => {
		setTruncate(event.target.value);
	};

	const [value, setValue] = React.useState(0);
	const handleTabChange = (_event: SyntheticEvent, newValue: number): void => {
		setValue(newValue);
	};

	const [loading, setLoading] = React.useState(false);

	const formData = useSelector(selectFormdata);
	const customerContactData = formData.customerContact;
	const returnRatioData = formData.returnRatio;
	const authConfig = getAuthConfig();
	const auth = useAuth();
	if (auth && auth.userData) {
		console.log(auth.userData);
		return (
			<Box>
				<GeneralInformation />
				<ColumnSelectionHelper marginBottom={0} />
				<Tabs value={value} onChange={handleTabChange}>
					<Tab
						label="Metrics"
						disabled={
							formData.testType == "return_ratio" ||
							formData.testType == "contact_ratio" ||
							formData.testType == "return_ratio_after_order"
						}
					/>
					<Tab label="Dimensions" />
					<Tab
						label="Return Ratio"
						disabled={
							formData.testType !== "return_ratio" &&
							formData.testType !== "return_ratio_after_order"
						}
					/>
					<Tab
						label="Contact Ratio"
						disabled={formData.testType !== "contact_ratio"}
					/>
				</Tabs>
				<TabPanel value={value} index={0}>
					<Metrics />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Dimensions />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<ReturnRatio {...returnRatioData} />
				</TabPanel>
				<TabPanel value={value} index={3}>
					<CustomerContact {...customerContactData} />
				</TabPanel>
				<Box sx={{ height: 10 }} />
				<Divider />
				<Box sx={{ height: 10 }} />
				<Grid item xs={3} paddingTop={4}>
					<Box>
						<Button
							preset="callToAction"
							loading={loading}
							onClick={() =>
								RunQueries(formData, auth.userData.id_token, setLoading)
							}
						>
							Build the dashboard
						</Button>
					</Box>
				</Grid>
			</Box>
		);
	}
	return <Login />;
}
