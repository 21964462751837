export const info = {'campaignId': 'The first 6 chars',
'startDate': 'The date from which you want to track data (inclusive)',
'endDate': 'The date from which you want to stop track data (inclusive)',
'observationType': 'What fields from the `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx` table in BigQuery do you need to define the sessions you want to include in your observations (denominator).',
'obsEventCategory': 'This fields corresponds with hits.event.eventcategory in the `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx` table. It uses regex. We will count all sessions that match all regular expressions.',
'obsEventAction': 'This fields corresponds with hits.event.eventaction in the `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx` table. It uses regex. We will count all sessions that match all regular expressions.',
'obsEventLabel': 'This fields corresponds with hits.event.eventlabel in the `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx` table. It uses regex. We will count all sessions that match all regular expressions.',
'obsPagepath': 'This fields corresponds with hits.page.pagepath in the `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx` table. It uses regex. We will count all sessions that match all regular expressions.',
'obsBigQueryField': 'You can select a column from `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx` to witch you can apply a regex match statement. We will count all sessions that match all regular expressions.',
'obsExpression': 'The regex you want to apply to the previously select column.',
'successesType': 'What fields from the `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx` table in BigQuery do you need to define the sessions you want to include in your successes (numerator).',
'sucEventCategory': 'This fields corresponds with hits.event.eventcategory in the `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx` table. It uses regex. We will count all sessions that match all regular expressions.',
'sucEventAction': 'This fields corresponds with hits.event.eventaction in the `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx` table. It uses regex. We will count all sessions that match all regular expressions.',
'sucEventLabel': 'This fields corresponds with hits.event.eventlabel in the `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx` table. It uses regex. We will count all sessions that match all regular expressions.',
'sucPagepath': 'This fields corresponds with hits.page.pagepath in the `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx` table. It uses regex. We will count all sessions that match all regular expressions.',
'sucBigQueryFieldOne': 'You can select a column from `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx` to witch you can apply a regex match statement. We will count all sessions that match all regular expressions.',
'sucExpressionOne': 'The regex you want to apply to the previously select column.',
'sucBigQueryFieldTwo': 'You can select a column from `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx` to witch you can apply a regex match statement. We will count all sessions that match all regular expressions.',
'sucExpressionTwo': 'The regex you want to apply to the previously select column.',
'dimensionAlias': 'The (column) name that you want to use for your dimension. Please note that this column name is also used as a Biqguery column name in staging tables. This is mandatory for example for device.devicecategory, to rename it to device.',
'dimensionDefinition': 'This is the definition of the dimension, which can be a column reference or a SQL statement, such as a CASE statement.'
}

export default {info}