import {
  CbColorBaseBlack,
  CbColorBaseBlueCoolblue,
  CbColorBaseBlueDarkblue,
  CbColorBaseBlueGreyblue,
  CbColorBaseGreyLightgrey,
  CbColorBaseGreyBrightgrey,
  CbColorBaseWhite,
  CbColorFontBase
} from "@coolblue-development/design-tokens"
import { alpha, createTheme } from "@mui/material/styles"

const sansSerifFonts = "Open Sans, Arial, Helvetica, Verdana, sans-serif"
const serifFonts =
  'DobraSlabBook, Georgia, Times New Roman, Times, "Droid Serif", serif'

const defaultHeaderStyles = {
  fontWeight: 600,
  lineHeight: 1.25
}

const defaultBodyStyles = {
  lineHeight: 1.5,
  fontSize: "1.3rem"
}

export default createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        mark: {
          backgroundColor: alpha(CbColorBaseBlueCoolblue, 0.1),
          color: "inherit"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "initial",
          "&.Mui-disabled": {
            borderColor: "inherit",
            boxShadow: "0px -2px 0px inset"
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
          color: "black",
          backgroundColor: CbColorBaseWhite,
          padding: createTheme().spacing(3),
          boxShadow: createTheme().shadows[2]
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: "black"
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        filledInfo: {
          backgroundColor: CbColorBaseBlueCoolblue,
          fontWeight: 600
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          color: "black",
          ".MuiDataGrid-row.Mui-selected": {
            backgroundColor: "transparent"
          },
          ".MuiDataGrid-row:hover": {
            cursor: "pointer"
          },
          "&.no-hover .MuiDataGrid-row:hover": {
            backgroundColor: "transparent",
            cursor: "auto"
          },
          ".MuiDataGrid-cellContent": {
            display: "-webkit-box",
            WebkitLineClamp: "3",
            WebkitBoxOrient: "vertical",
            whiteSpace: "normal",
            wordWrap: "break-word"
          },
          ".MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1
          },
          ".bulk-actions": {
            backgroundColor: CbColorBaseBlueGreyblue,
            margin: "5px 0 -1px"
          },
          ".MuiDataGrid-columnHeader--filledGroup": {
            padding: 0
          }
        },
        columnHeaderTitle: {
          fontWeight: 700,
          letterSpacing: "-0.01em"
        },
        iconSeparator: {
          display: "none"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "black",
          fontWeight: 700,
          "&.Mui-focused": {
            color: "black"
          },
          paddingBottom: "0.8rem"
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "7px"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: CbColorBaseWhite,
          "&.Mui-disabled": {
            backgroundColor: CbColorBaseGreyBrightgrey
          },
          "&.Mui-disabled input": {
            color: CbColorBaseBlack,
            WebkitTextFillColor: CbColorBaseBlack,
            borderColor: "blue"
          },
          "&.Mui-disabled input::selection": {
            color: "black"
          },
          "&.Mui-disabled.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: CbColorBaseGreyLightgrey
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: CbColorBaseGreyLightgrey
        }
      }
    }
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: sansSerifFonts,
    h1: {
      ...defaultHeaderStyles,
      color: CbColorBaseBlueDarkblue,
      fontFamily: serifFonts,
      fontSize: "2.0rem",
      fontWeight: 800,
      marginBottom: 8,
      letterSpacing: "0.15px"
    },
    h2: {
      ...defaultHeaderStyles,
      fontSize: "2.0rem"
    },
    h3: {
      ...defaultHeaderStyles,
      fontSize: "2.4rem"
    },
    h4: {
      ...defaultHeaderStyles,
      fontSize: "2.2rem"
    },
    h5: {
      ...defaultHeaderStyles,
      fontSize: "2.1rem"
    },
    h6: {
      ...defaultHeaderStyles,
      fontSize: "1.8rem"
    },
    body1: {
      ...defaultBodyStyles
    },
    body2: {
      ...defaultBodyStyles,
      color: CbColorFontBase
    }
  }
})
