import { AuthProviderProps, User } from 'oidc-react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { updateUsername } from "../Form/FormSlice"

const { REACT_APP_API_URL } = process.env;
axios.defaults.baseURL = 'http://localhost:3001/';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';


export const setToken = (token: string) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const setTokenFromUserData = (userData: User | null) => {
  if (userData?.access_token) {
    setToken(userData.access_token);
  }
};


export const removeSearchParamsFromUrl = () => {
  const { protocol, host, pathname } = window.location;
  const base = `${protocol}//${host}`;
  const newUrl = new URL(`${pathname}`, base).toString();
  window.history.replaceState(null, '', newUrl);
};

export const getAuthConfig = (): AuthProviderProps => {
  return {
    authority: "https://auth-service.eu-west-1.coolblue-testing.eu/",
    clientId: "custom-analysis-tool",
    redirectUri: window.location.href.split('?')[0],
    responseType: 'code',
    scope: "openid",
    automaticSilentRenew: true,
     onSignIn: userData => {
        removeSearchParamsFromUrl()
        try {console.log(userData.profile.sub)}
        catch {console.log('console.log failed')}
    }
  };
};



