import React, { Component } from "react"
import { Box, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography } from "@coolblue-development/becky"

interface ComponentProps {
    selectedQuery: string;
}


export default function SelectedQuery(query) {
    const queryString = query[1]
    const queryName = query[0]
    console.log(query)
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h2" gutterBottom component="div">
              Selected Query
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} paddingBottom={4}>
            <TextField fullWidth label={queryName} multiline minRows={4} maxRows={4} value={queryString} />
        </Grid>
      </Grid>
    )
}