import React, { Component, useState } from "react";
import {
	Box,
	Button,
	Divider,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	FormControl,
	Grid,
	MenuItem,
	InputLabel,
	Select,
	TextField,
	Typography,
} from "@coolblue-development/becky";
import { Route, Routes, NavLink, HashRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	selectFormdata,
	addMetric,
	addContinuousMetric,
	removeMetric,
	removeContinuousMetric,
	updateActiveMetric,
} from "../../Form/FormSlice";
import { info } from "../../Form/Info";
import { CircleQuestionmark, Cross } from "@coolblue-development/icons";
import ColumnSelectionHelper from '../../Components/ColumnSelectionHelper'
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

export default function AddMetric(activeMetric) {
	const dispatch = useDispatch();
	const [metricForm, setMetricForm] = React.useState({ activeMetric });
	const formData = useSelector(selectFormdata);
    const metricType = formData.metricType;
    if (metricType == 0) {
        const metricsArray = formData.metrics;
    } else {
        const metricsArray = formData.continuousMetrics;
    }
	const metricsArray = formData.metrics;

	const [succesesType, setSuccesesType] = React.useState(
		activeMetric["successesType"]
	);
	const handleSuccesTypeChange = (event) => {
		setSuccesesType(event.target.value);
	};
	const [observationType, setObservationType] = React.useState(
		activeMetric["observationType"]
	);
	const handleObservationTypeChange = (event) => {
		setObservationType(event.target.value);
	};
	const [open, setOpen] = useState(false);
	const [content, setContent] = useState("");

	const handleFormChange = (event) => {
		const key = event.target.name;
		const value = event.target.value;
		if (key === "observationType") {
			handleObservationTypeChange(event);
		}
		if (key === "successesType") {
			handleSuccesTypeChange(event);
		}
		const newData = metricForm;
		newData[key] = value;
		setMetricForm(newData);
	};

	const handleOpen = (fieldTopic): void => {
		setOpen(true);
		setContent(info[fieldTopic]);
	};
	const handleClose = (): void => {
		setOpen(false);
	};

	function getCorrectMetricForm() {
		const copyMetricForm = { ...metricForm };
		const activeMetricCopy = { ...copyMetricForm.activeMetric };
		const newDict = {};
		Object.keys(activeMetricCopy).forEach((item) => {
			if (item !== "activeMetric") {
				newDict[item] = activeMetricCopy[item];
			}
		});
		Object.keys(copyMetricForm).forEach((item) => {
			if (item !== "activeMetric") {
				newDict[item] = copyMetricForm[item];
			}
		});
		return newDict;
	}

	function handleSubmit() {
		const activeMetricForm = getCorrectMetricForm();
		const metric = metricsArray.find(
			(metric) => metric.metricName === activeMetricForm.metricName
		);
		const itemIndex = metricsArray.indexOf(metric);
		if (itemIndex === -1) {
			if (metricType == 0) {
				dispatch(addMetric(activeMetricForm));
			} else {
				dispatch(addContinuousMetric(activeMetricForm));
			}
		} else {
			dispatch(removeMetric(activeMetricForm.removeMetric));
			dispatch(addMetric(activeMetricForm));
		}
		dispatch(updateActiveMetric({}));
	}

	function getSelectorValue(name, default_value) {
		if (metricForm[name] !== undefined) {
			return metricForm[name];
		} else if (activeMetric[name] !== undefined) {
			return activeMetric[name];
		} else {
			return default_value;
		}
	}

	function checkType(section, type) {
		if (metricType == 0 || type != "successesType") {
			if (
				metricForm[type] === undefined &&
				metricForm.activeMetric[type] !== undefined &&
				section.includes(metricForm.activeMetric[type])
			) {
				return true;
			} else if (
				metricForm[type] !== undefined &&
				section.includes(metricForm[type])
			) {
				return true;
			} else if (
				metricForm[type] === undefined &&
				section.includes("pagepath") &&
				type == "successesType"
			) {
				return true;
			} else return false;
		} else if (
			metricType == 1 &&
			type == "successesType" &&
			section.includes("continuous")
		) {
			return true;
		} else {
			return false;
		}
	}

	function FormFieldBuilder(props) {
		const inputLabelId = props.name + "label";
		switch (props.type) {
			case "select":
				return (
					<FormControl fullWidth>
						<InputLabel id={inputLabelId}>
							{props.label}
							<IconButton onClick={() => handleOpen(props.name)}>
								<CircleQuestionmark />
							</IconButton>
						</InputLabel>
						<Select
							labelId={inputLabelId}
							name={props.name}
							defaultValue={activeMetric[props.name]}
							value={getSelectorValue(props.name, props.default_value)}
							label={props.label}
							onChange={(e) => {
								handleFormChange(e);
							}}
						>
							{Object.entries(props.options).map(([key, value]) => (
								<MenuItem value={key}>{value}</MenuItem>
							))}
							;
						</Select>
					</FormControl>
				);
			case "textfield":
				return (
					<TextField
						name={props.name}
						label={props.label}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => handleOpen(props.name)}>
										<CircleQuestionmark />
									</IconButton>{" "}
								</InputAdornment>
							),
						}}
						placeholder={props.placeholder}
						defaultValue={activeMetric[props.name]}
						value={metricForm[props.name]}
						helperText={
							props.name == "metricName"
								? "Don't use spaces or funky characters"
								: ""
						}
						onChange={(e) => {
							handleFormChange(e);
						}}
					/>
				);
		}
	}
	return (
		<Grid container>
			<Grid item xs={12}>
				<Box>
					<Typography variant="h2" gutterBottom component="div">
						Add or Edit Custom Metric
					</Typography>
				</Box>
			</Grid>
			<ColumnSelectionHelper/>
			<Grid item xs={12} paddingBottom={4} paddingTop={4}>
				<Box>
					<FormFieldBuilder
						{...{
							type: "textfield",
							name: "metricName",
							label: "Metric Name",
							placeholder: "e.g. ConversionRate",
						}}
					/>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box>
					<Divider />
				</Box>
			</Grid>
			<Grid item xs={12} paddingBottom={4} paddingTop={4}>
				<Grid item xs={2} paddingBottom={4}>
					<Box paddingRight={4}>
						<FormFieldBuilder
							{...{
								type: "select",
								name: "observationType",
								default_value: "all",
								label: "Observations Type",
								options: {
									all: "All visits of the test",
									pagepath: "All visits that saw a page",
									event: "All visits that saw an event",
									event_pagepath: "All visits that saw a page and event",
									custom: "Custom Condition",
								},
							}}
						/>
					</Box>
				</Grid>
			</Grid>
			<Grid container>
				{checkType(["event", "event_pagepath"], "observationType") && (
					<Grid container xs={12}>
						<Grid item xs={2} paddingBottom={4}>
							<FormFieldBuilder
								{...{
									type: "textfield",
									name: "obsEventCategory",
									label: "Event Category",
									placeholder: "e.g. Responsive Product Page",
								}}
							/>
						</Grid>
						<Grid item xs={2} paddingBottom={4}>
							<FormFieldBuilder
								{...{
									type: "textfield",
									name: "obsEventAction",
									label: "Event Action",
									placeholder: "e.g. Add to cart button",
								}}
							/>
						</Grid>
						<Grid item xs={2} paddingBottom={4}>
							<FormFieldBuilder
								{...{
									type: "textfield",
									name: "obsEventLabel",
									label: "Event Label",
									placeholder: "e.g. 213556",
								}}
							/>
						</Grid>
					</Grid>
				)}
				{checkType(["pagepath", "event_pagepath"], "observationType") && (
					<Grid item xs={2} paddingBottom={4}>
						<FormFieldBuilder
							{...{
								type: "textfield",
								name: "obsPagepath",
								label: "Pagepath",
								placeholder: 'e.g. "/product/.*"',
							}}
						/>
					</Grid>
				)}
				{checkType(["custom"], "observationType") && (
					<Grid container xs={12}>
						<Grid item xs={2} paddingBottom={4}>
							<FormFieldBuilder
								{...{
									type: "textfield",
									name: "obsBigQueryField",
									label: "BigQuery Field",
									placeholder: "e.g. 'device.DeviceCategory'",
								}}
							/>
						</Grid>
						<Grid item xs={2} paddingBottom={4}>
							<FormFieldBuilder
								{...{
									type: "textfield",
									name: "obsExpression",
									label: "Expression",
									placeholder: "e.g. '(mobile|desktop)'",
								}}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Grid item xs={12}>
				<Box>
					<Divider />
				</Box>
			</Grid>
			<Grid item xs={12} paddingBottom={4}>
				{metricType == 0 && (
					<Grid item xs={2} paddingTop={4} paddingBottom={4}>
						<Box paddingRight={4}>
							<FormFieldBuilder
								{...{
									type: "select",
									name: "successesType",
									default_value: "pagepath",
									label: "Successes Type",
									options: {
										pagepath: "All visits that saw a page",
										event: "All visits that saw an event",
										event_pagepath: "All visits that saw a page and event",
										custom: "Custom condition",
										two_custom: "Two custom conditions",
										pagepath_custom: "Pagepath and custom condition",
									},
								}}
							/>
						</Box>
					</Grid>
				)}
			</Grid>
			<Grid container>
				{checkType(["event", "event_pagepath"], "successesType") && (
					<Grid container xs={12}>
						<Grid item xs={2} paddingBottom={4}>
							<FormFieldBuilder
								{...{
									type: "textfield",
									name: "sucEventCategory",
									label: "Event Category",
									placeholder: "e.g. Responsive Product Page",
								}}
							/>
						</Grid>
						<Grid item xs={2} paddingBottom={4}>
							<FormFieldBuilder
								{...{
									type: "textfield",
									name: "sucEventAction",
									label: "Event Action",
									placeholder: "e.g. Add to cart button",
								}}
							/>
						</Grid>
						<Grid item xs={2} paddingBottom={4}>
							<FormFieldBuilder
								{...{
									type: "textfield",
									name: "sucEventLabel",
									label: "Event Label",
									placeholder: "e.g. 213556",
								}}
							/>
						</Grid>
					</Grid>
				)}
				{checkType(
					["pagepath", "event_pagepath", "pagepath_custom"],
					"successesType"
				) && (
					<Grid item xs={2} paddingBottom={4}>
						<FormFieldBuilder
							{...{
								type: "textfield",
								name: "sucPagepath",
								label: "Pagepath",
								placeholder: 'e.g. "/product/.*"',
							}}
						/>
					</Grid>
				)}
				{checkType(
					["custom", "two_custom", "pagepath_custom"],
					"successesType"
				) && (
					<Grid container xs={12}>
						<Grid item xs={2} paddingBottom={4}>
							<FormFieldBuilder
								{...{
									type: "textfield",
									name: "sucBigQueryFieldOne",
									label: "BigQuery Field",
									placeholder: "e.g. 'device.DeviceCategory'",
								}}
							/>
						</Grid>
						<Grid item xs={2} paddingBottom={4}>
							<FormFieldBuilder
								{...{
									type: "textfield",
									name: "sucExpressionOne",
									label: "Expression",
									placeholder: "e.g. '(mobile|desktop)'",
								}}
							/>
						</Grid>
					</Grid>
				)}
				{checkType(["two_custom"], "successesType") && (
					<Grid container xs={12}>
						<Grid item xs={2} paddingBottom={4}>
							<FormFieldBuilder
								{...{
									type: "textfield",
									name: "sucBigQueryFieldTwo",
									label: "BigQuery Field",
									placeholder: "e.g. 'device.DeviceCategory'",
								}}
							/>
						</Grid>
						<Grid item xs={2} paddingBottom={4}>
							<FormFieldBuilder
								{...{
									type: "textfield",
									name: "sucExpressionTwo",
									label: "Expression",
									placeholder: "e.g. '(mobile|desktop)'",
								}}
							/>
						</Grid>
					</Grid>
				)}
				{checkType(["continuous"], "successesType") && (
					<Grid container xs={12}>
						<Grid item xs={2} paddingBottom={4}>
							<FormFieldBuilder
								{...{
									type: "textfield",
									name: "sucBigQueryFieldOne",
									label: "BigQuery Field",
									placeholder: "e.g. 'transaction margin'",
								}}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>

			<Grid item xs={12}>
				<Box>
					<Divider />
				</Box>
			</Grid>

			<Grid item xs={6} paddingTop={4} paddingBottom={4}>
				<Box>
					<Button
						component={NavLink}
						to="/"
						preset="callToAction"
						onClick={() => handleSubmit()}
					>
						Submit
					</Button>
				</Box>
			</Grid>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>
					A little help
					<IconButton
						onClick={handleClose}
						preset="text"
						size="small"
						aria-label="Close dialog"
					>
						<Cross />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>{content}</DialogContentText>
				</DialogContent>
			</Dialog>
		</Grid>
	);
}
