import { Box } from "@coolblue-development/becky";
import { BeckyThemeProvider } from '@coolblue-development/themes';
import {
  Route,
  Routes,
  HashRouter
} from "react-router-dom";

import Home from "./Screens/Home";
import CustomMetric from "./Screens/CustomMetric";
import CustomDimension from "./Screens/CustomDimension";
import StandardMetric from "./Screens/StandardMetric"
import StandardDimension from "./Screens/StandardDimension"
import { AuthProvider } from 'oidc-react';
import { getAuthConfig } from "./Auth/AuthConfig"
import AppBarComponent from "./Components/Appbar";

const authConfig = getAuthConfig();

export default function Main(){

    return (

      <BeckyThemeProvider>
      <AuthProvider {...authConfig}>

      <HashRouter>
        <AppBarComponent />
          <Box
            sx={{
              bgcolor: 'background.paper',
              padding: 6,
              margin: 6
            }}
          >
            <Routes >
                <Route exact path="/" element={<Home />}/>
                <Route exact path="/CustomMetric" element={<CustomMetric />}/>
                <Route exact path="/CustomDimension" element={<CustomDimension />}/>
                <Route exact path="/StandardMetric" element={<StandardMetric />}/>
                <Route exact path="/StandardDimension" element={<StandardDimension />}/>
            </Routes>
          </Box>
      </HashRouter>
      </AuthProvider>
      </BeckyThemeProvider>
    );
}