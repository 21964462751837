import React, { Component } from "react"
import { Box, Button, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography } from "@coolblue-development/becky"
import {
  Route,
  Routes,
  NavLink,
  HashRouter
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import {
    updateReturnRatio
} from '../../Form/FormSlice'

export default function ReturnRatio(inputReturnRatioData) {
      const dispatch = useDispatch();
      const [returnRatioData, setReturnRatioData] = React.useState({inputReturnRatioData});
      const [toggle, setToggle] = React.useState(returnRatioData.toggle);
      const [returnType, setReturnType] = React.useState(returnRatioData.contactType);
      const handleToggleChange = (event) => {setToggle(event.target.value);};
      const handleReturnTypeChange = (event) => {setReturnType(event.target.value);};

      const handleFormChange = (event) => {
        const key = event.target.name;
        const value = event.target.value
        if (key==='toggle') {handleToggleChange(event)}
        if (key==='returnType') {handleReturnTypeChange(event)}
        const newData = returnRatioData
        newData[key] = value;
        setReturnRatioData(newData);
     };

    function getSelectorValue(name, default_value) {
        if (returnRatioData[name] !== undefined) {
            return returnRatioData[name]
        } else if (inputReturnRatioData[name] !== undefined) {
            return inputReturnRatioData[name]
        } else {
            return default_value
        }
    }

    function getCorrectReturnRatioData() {
      const copyReturnRatioData = {...returnRatioData}
      const activeReturnRatioCopy = {...copyReturnRatioData.inputReturnRatioData}
      const newDict = {}


       Object.keys(activeReturnRatioCopy).forEach((item) => {
            if( item !== "inputReturnRatioData") {
                console.log(newDict, activeReturnRatioCopy[item], item)
                newDict[item] = activeReturnRatioCopy[item]
            }
        })
      Object.keys(copyReturnRatioData).forEach((item) => {
            if(item !== "inputReturnRatioData") {
            console.log(newDict, copyReturnRatioData[item], item)
                newDict[item] = copyReturnRatioData[item]
            }
        })

      return newDict
    }

     function handleSubmit() {
          const activeReturnRatioData = getCorrectReturnRatioData()
          dispatch(updateReturnRatio(activeReturnRatioData))
    };


    function FormFieldBuilder(props) {
        const inputLabelId = props.name + 'label'
        switch(props.type) {
            case 'select':
                return(
                  <FormControl fullWidth>
                    <InputLabel id={props.inputLabelId}>{props.label}</InputLabel>
                    <Select
                      labelId={props.inputLabelId}
                      name={props.name}
                      defaultValue={inputReturnRatioData[props.name]}
                      value={getSelectorValue(props.name, props.default_value)}
                      label={props.label}
                      onChange={(e) => { handleFormChange(e);
                      ;}}>

                      {Object.entries(props.options).map(([key,value])=>
                        <MenuItem value={key}>{value}</MenuItem>
                      )};
                    </Select>
                  </FormControl>)
            case 'textfield':
                return(

                 <TextField name={props.name} label={props.label} placeholder={props.placeholder}
                    defaultValue={inputReturnRatioData[props.name]}
                    value={returnRatioData[props.name]}
                    onChange={(e) => {handleFormChange(e);}}/>
                 )
            }
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h2" gutterBottom component="div">
              Return Ratio
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} paddingBottom={4} paddingTop={4}>
            <Grid item xs={2} paddingBottom={4}>
            <Box paddingRight={4}>
                <FormFieldBuilder
                    { ...{
                        type: 'select', name: 'toggle', default_value: 'no', label: 'Analyze return ratio',
                        options:
                            {no: 'No',
                            all: 'Yes, on all return reasons',
                            specific: 'Yes, on specific set of return reasons'}
                            }}/>
                </Box>
            </Grid>
        </Grid>
        {getSelectorValue('toggle', 'no') !== 'no' && (
        <Grid container xs={12}>
        <Grid item xs={2} paddingBottom={4}>
            <FormFieldBuilder {...{type: 'textfield', name: 'startDate', label: 'Start Date', placeholder: 'e.g. 2023-01-01'}} />
        </Grid>
        <Grid item xs={2} paddingBottom={4}>
            <FormFieldBuilder {...{type: 'textfield', name: 'endDate', label: 'End Date', placeholder: 'e.g. 2023-01-01'}} />
        </Grid>
        <Grid item xs={12} paddingBottom={4} paddingTop={4}>
            <Grid item xs={2} paddingBottom={4}>
            <Box paddingRight={4}>
                <FormFieldBuilder
                    { ...{
                        type: 'select', name: 'returnType', default_value: 'logged', label: 'Return ratio calculation',
                        options:
                            {product_orders: 'Unique products returned / placed orders',
                            orders_orders: 'Unique orders returned / placed orders',
                            product_visits: 'Unique products returned / visits',
                            orders_visits: 'Unique orders returned / visits',
                            customers_visits: 'Unique customers that returned / visits',
                            }
                            }}/>
                </Box>
            </Grid>
        </Grid>
        </Grid>)}
        {getSelectorValue('toggle', 'no') === 'specific' && (
        <Grid container xs={12}>
        <Grid item xs={2} paddingBottom={4}>
            <FormFieldBuilder {...{type: 'textfield', name: 'returnReasons', label: 'Return Reasons', placeholder: '1234,134564,1234'}} />
        </Grid>
        </Grid>)}

          <Grid container>
            <Grid item xs={3}>
                <Box>
                    <Button preset="callToAction" onClick={() => handleSubmit()}>Save</Button>
                </Box>
            </Grid>
        </Grid>
      </Grid>

    )
}