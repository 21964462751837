import React, { Component, useState } from "react"
import { Box, Button, Chip, DataGrid, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography } from "@coolblue-development/becky"
import { ArrowDown, Cross, BeckyRound, Plus } from '@coolblue-development/icons';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Edit from '@mui/icons-material/Edit';
import { useSelector, useDispatch } from 'react-redux'
import { LicenseInfo } from '@mui/x-license-pro';
import {red, green, blue} from '../Components/Colors'
import Login from '../Components/Login'
import { useNavigate } from "react-router-dom";
import { useAuth } from 'oidc-react';
import { getAuthConfig } from "../Auth/AuthConfig"
import {
  Route,
  Routes,
  NavLink,
  HashRouter
} from "react-router-dom";
import {
  selectFormdata,
    addDimension
} from '../Form/FormSlice'
import {initialDimensions} from '../Form/StandardMetricsDimsMap'


function getChipProps(params: GridRenderCellParams): ChipProps {
  if (params.value === "Add"){
    return {
      icon: <Plus style={{ fill: green }}/>,
      label: params.value,
     style: {
        borderColor: green
      }
    };
  }
}


export default function StandardDimension() {
        const dispatch = useDispatch();
        const formData = useSelector(selectFormdata);
        const addedDimensions = formData.dimensions
        const columns = [
          {
            field: 'id',
            headerName: 'ID',
            width: 10,
            editable: false,
          },
          {
            field: 'dimensionAlias',
            headerName: 'Dimension Name',
            width: 250,
            editable: false,
          },
          {
            field: 'dimensionDefinition',
            headerName: 'Dimension Definition',
            width: 250,
            editable: false,
          },
          {
            field: 'Add',
            headerName: 'Add',
            renderCell: (params) => {
                return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
            },
            width: 150,
            editable: false,
          }
        ];


        function checkIfDimensionIsNotAdded(dimension) {
        if(addedDimensions.some(e => e.dimensionAlias == dimension)) {
            return false
        } else {return true}
        }

        const [dimensionsArray, setDimensionsArray] = useState(initialDimensions);
        const dimArray = dimensionsArray.filter((dimension) => checkIfDimensionIsNotAdded(dimension.dimensionAlias))
        let filteredDimensions = dimArray.filter(dimension => {
            return dimension.testTypes.length === 0 || dimension.testTypes.indexOf(formData.testType) !== -1
        })
        const detailsrows = filteredDimensions.map((row, index) => {

        return {
            id: row.id + 1,
            dimensionAlias: row.dimensionAlias,
            dimensionDefinition: row.dimensionDefinition,
            Add: row.action,
        }
        })


        function handleAdding(params) {
            var copyDimensionsArray = [...dimensionsArray];
            const dimension = copyDimensionsArray.find( dimension => dimension.dimensionAlias === params.row.dimensionAlias)
            const itemIndex = copyDimensionsArray.indexOf(dimension)
            copyDimensionsArray.splice(itemIndex, 1);
            setDimensionsArray(copyDimensionsArray);
            console.log(params.row.dimensionAlias)
            dispatch(addDimension(params.row));
        }


        const authConfig = getAuthConfig();
        const auth = useAuth();
        if (auth && auth.userData) {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Box>
                            <Typography variant="h2" gutterBottom component="div">
                                Add a standard dimension
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <DataGrid
                            rows={detailsrows}
                            columns={columns}
                            onCellClick={handleAdding}
                            initialState = {
                                {
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false,
                                        },
                                    },
                                }
                            }
                            />
                        </Box>
                    </Grid>
                            <Grid item xs={6} paddingTop={4} paddingBottom={4}>
              <Box>
                <Button component={NavLink} to="/" preset="callToAction">Home</Button>
              </Box>
            </Grid>
                </Grid>
            )
        }
    return (<Login />)
}


