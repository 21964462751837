import { AppBar, Avatar, IconButton, Menu, Drawer } from "@coolblue-development/becky";
import React, { Component, useState } from "react";
import {
    selectFormdata,
} from '../Form/FormSlice'
import { useSelector, useDispatch } from 'react-redux'
import { Becky } from '@coolblue-development/icons';
import { useAuth } from 'oidc-react';
import { getAuthConfig } from "../Auth/AuthConfig"

export default function AppBarComponent() {
const authConfig = getAuthConfig();
const formData = useSelector(selectFormdata);
const { isLoading, userData } = useAuth();
var appBarUsername = formData.username;
if (isLoading || !userData) {
    appBarUsername = formData.username;
} else {appBarUsername = userData?.profile?.sub;}


return (
  <>
    <AppBar
      position={"static"}
      title={"Custom Analysis Tool"}
      avatar={<Avatar><Becky /></Avatar>}
      username={appBarUsername}
    />
  </>)
}
