import React, { Component } from "react"
import { Box, Button, Divider, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography } from "@coolblue-development/becky"
import AddMetric from '../Components/AddMetric/AddMetric'
import Login from '../Components/Login'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectFormdata,
} from '../Form/FormSlice'
import { useAuth } from 'oidc-react';
import { getAuthConfig } from "../Auth/AuthConfig"

export default function CustomMetric() {
    const formData = useSelector(selectFormdata);
    const activeMetric = formData.activeMetric
    const authConfig = getAuthConfig();
    const auth = useAuth();
    if (auth && auth.userData) {
        console.log(auth.userData)
        return (
        <AddMetric {...activeMetric} />
        )
    }

    return (<Login />)
}