export const metricsDefinition = {'ConversionRate': {metricName: 'ConversionRate', observationType: 'all', successesType: 'custom', sucBigQueryFieldOne: 'totals.transactions', sucExpressionOne: '1'},
'AddtoCartRate': {metricName: 'AddtoCartRate', observationType: 'all', successesType: 'custom', sucBigQueryFieldOne: 'enrichment_addToCart', sucExpressionOne: '1'},
'BounceRate': {metricName: 'BounceRate', observationType: 'all', successesType: 'custom', sucBigQueryFieldOne: 'totals.bounces', sucExpressionOne: '1'},
'CheckoutConversion': {metricName: 'CheckoutConversion', observationType: 'custom', obsBigQueryField: 'enrichment_Checkout_Seen', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'order_placed', sucExpressionOne: '1'},
'ContactOptionClicked': {metricName: 'ContactOptionClicked', observationType: 'all', successesType: 'event', sucEventCategory: '^(Customer Service|Contact Wizard)$', sucEventAction: '^Contactbox', sucEventLabel: '^(Phone|Facebook|Twitter|Email|Shops|Whatsapp|Call me back)$'},
'LoginRate': {metricName: 'LoginRate', observationType: 'all', successesType: 'custom', sucBigQueryFieldOne: 'enrichment_login', sucExpressionOne: '1'},
'ShoppingCartConversion': {metricName: 'ShoppingCartConversion', observationType: 'custom', obsBigQueryField: 'enrichment_Cart_seen', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'order_placed', sucExpressionOne: '1'},
'ShoppingCartContinuationRate': {metricName: 'ShoppingCartContinuationRate', observationType: 'custom', obsBigQueryField: 'enrichment_Cart_seen', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'enrichment_Checkout_Seen', sucExpressionOne: '1'},
'LoginPageContinuationRate': {metricName: 'LoginPageContinuationRate', observationType: 'custom', obsBigQueryField: 'enrichment_Login_Page_Seen', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'enrichment_Checkout_Seen', sucExpressionOne: '1'},
'LoginPageConversionRate': {metricName: 'LoginPageConversionRate', observationType: 'custom', obsBigQueryField: 'enrichment_Login_Page_Seen', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'order_placed', sucExpressionOne: '1'},
'DetailsPageContinuationRate': {metricName: 'LoginPageContinuationRate', observationType: 'all', successesType: 'custom', sucBigQueryFieldOne: 'Details_page_continuation_rate_session', sucExpressionOne: '1'},
'DetailsPageConversionRate': {metricName: 'LoginPageConversionRate', observationType: 'custom', obsBigQueryField: 'Details_page_continuation_rate_session', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'order_placed', sucExpressionOne: '1'},
'DeliveryMethodPageContinuationRate': {metricName: 'DeliveryMethodPageContinuationRate', observationType: 'custom', obsBigQueryField: 'enrichment_Delivery_Method_Page_Seen', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'enrichment_Checkout_Seen', sucExpressionOne: '1'},
'DeliveryMethodPageConversionRate': {metricName: 'DeliveryMethodPageConversionRate', observationType: 'custom', obsBigQueryField: 'enrichment_Delivery_Method_Page_Seen', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'order_placed', sucExpressionOne: '1'},
'TimeslotContinuationRate': {metricName: 'TimeslotContinuationRate', observationType: 'custom', obsBigQueryField: 'enrichment_coolblue_timeslot_seen', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'enrichment_Checkout_Seen', sucExpressionOne: '1'},
'TimeslotPageConversionRate': {metricName: 'TimeslotPageConversionRate', observationType: 'custom', obsBigQueryField: 'enrichment_coolblue_timeslot_seen', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'order_placed', sucExpressionOne: '1'},
'OverviewPageContinuationRate': {metricName: 'OverviewPageContinuationRate', observationType: 'all', successesType: 'custom', sucBigQueryFieldOne: 'Overview_page_continuation_rate_session', sucExpressionOne: '1'},
'OverviewPageConversionRate': {metricName: 'OverviewPageConversionRate', observationType: 'custom', obsBigQueryField: 'Overview_page_continuation_rate_session', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'order_placed', sucExpressionOne: '1'},
'PaymentPageContinuationRate': {metricName: 'PaymentPageContinuationRate', observationType: 'all', successesType: 'custom', sucBigQueryFieldOne: 'Payment_page_continuation_rate_session', sucExpressionOne: '1'},
'PaymentPageConversionRate': {metricName: 'PaymentPageConversionRate', observationType: 'custom', obsBigQueryField: 'Payment_page_continuation_rate_session', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'order_placed', sucExpressionOne: '1'},
'TransactionMarginPerOrder': {metricName: 'TransactionMarginPerOrder', observationType: 'custom', obsBigQueryField: 'order_placed', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'transaction_margin'},
'TransactionMarginPerVisit': {metricName: 'TransactionMarginPerOrder', observationType: 'all', successesType: 'custom', sucBigQueryFieldOne: 'transaction_margin'},
'SalesPerOrder': {metricName: 'SalesPerOrder', observationType: 'custom', obsBigQueryField: 'order_placed', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'order_value'},
'SalesPerVisit': {metricName: 'SalesPerVisit', observationType: 'all', successesType: 'custom', sucBigQueryFieldOne: 'order_value'},
'AttachedProductQuantityPerOrder': {metricName: 'AttachedProductQuantityPerOrder', observationType: 'custom', obsBigQueryField: 'order_placed', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'attached_product_quantity'},
'AttachedProductMarginPerOrder': {metricName: 'AttachedProductMarginPerOrder', observationType: 'custom', obsBigQueryField: 'order_placed', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'attached_product_margin_including_all_agreements'},
'PrimaryProductMarginPerOrder': {metricName: 'PrimaryProductMarginPerOrder', observationType: 'custom', obsBigQueryField: 'order_placed', obsExpression: '1', successesType: 'custom', sucBigQueryFieldOne: 'PrimaryProductMarginPerOrder'},
}
export const initialDimensions = [
  { id: 0, dimensionAlias: 'Device', dimensionDefinition: 'device.deviceCategory', action: 'Add', testTypes: []},
  { id: 1, dimensionAlias: 'Subsidiary', dimensionDefinition: 'enrichment_subsidiary_name', action: 'Add', testTypes: []},
  { id: 2, dimensionAlias: 'Total', dimensionDefinition: "'Total'", action: 'Add', testTypes: []},
  { id: 3, dimensionAlias: 'CustomerRecognition', dimensionDefinition: "enrichment_landing_customer_recognition", action: 'Add', testTypes: []},
  { id: 4, dimensionAlias: 'Language', dimensionDefinition: 'enrichment_site_languageid', action: 'Add', testTypes: []},
  { id: 5, dimensionAlias: 'laptop_seen', dimensionDefinition: 'laptop_seen', action: 'Add', testTypes: ['precart_prdt']},
  { id: 6, dimensionAlias: 'tv_seen', dimensionDefinition: 'tv_seen', action: 'Add', testTypes: ['precart_prdt']},
  { id: 7, dimensionAlias: 'mobile_seen', dimensionDefinition: 'mobile_seen', action: 'Add', testTypes: ['precart_prdt']},
  { id: 8, dimensionAlias: 'washing_machine_seen', dimensionDefinition: 'washing_machine_seen', action: 'Add', testTypes: ['precart_prdt']},
  { id: 9, dimensionAlias: 'smart_home_seen', dimensionDefinition: 'smart_home_seen', action: 'Add', testTypes: ['precart_prdt']},
  { id: 10, dimensionAlias: 'apple_seen', dimensionDefinition: 'apple_seen', action: 'Add', testTypes: ['precart_prdt']},
  { id: 11, dimensionAlias: 'product_type', dimensionDefinition: 'product_type', action: 'Add', testTypes: ['precart_prdt']},
];
export const initialMetrics = [
    { id: 0, name: 'AddtoCartRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 1, name: 'BounceRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 2, name: 'ConversionRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 3, name: 'CheckoutConversion', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 4, name: 'ContactOptionClicked', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 5, name: 'LoginRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 6, name: 'ShoppingCartConversion', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 7, name: 'ShoppingCartContinuationRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 8, name: 'LoginPageContinuationRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 9, name: 'LoginPageConversionRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 10, name: 'DetailsPageContinuationRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 11, name: 'DetailsPageConversionRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 12, name: 'DeliveryMethodPageContinuationRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 13, name: 'DeliveryMethodPageConversionRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 14, name: 'TimeslotContinuationRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 15, name: 'TimeslotPageConversionRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 16, name: 'OverviewPageContinuationRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 17, name: 'OverviewPageConversionRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 18, name: 'PaymentPageContinuationRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 19, name: 'PaymentPageConversionRate', action: 'Add', metricType: 0, blockedTestTypes : []},
    { id: 20, name: 'TransactionMarginPerOrder', action: 'Add', metricType: 1, blockedTestTypes : []},
    { id: 21, name: 'TransactionMarginPerVisit', action: 'Add', metricType: 1, blockedTestTypes : []},
    { id: 22, name: 'SalesPerVisit', action: 'Add', metricType: 1, blockedTestTypes : []},
    { id: 23, name: 'SalesPerOrder', action: 'Add', metricType: 1, blockedTestTypes : []},
    { id: 24, name: 'AttachedProductQuantityPerOrder', action: 'Add', metricType: 1, blockedTestTypes : []},
    { id: 25, name: 'AttachedProductMarginPerOrder', action: 'Add', metricType: 1, blockedTestTypes : []},
    { id: 26, name: 'PrimaryProductMarginPerOrder', action: 'Add', metricType: 1, blockedTestTypes : []}
];

export const testTypeVisitsSupport = {'precart': 'The Pre cart option includes all visitors that have seen the variation in this session or a previous session.',
'precart_prdt': 'This Pre cart option includes all visitors that have seen the variation in this session or a previous session.',
'checkout': 'The Checkout option includes all visitors that have seen the variation in this session.',
'energy': 'The Energy option includes all visitors that have seen the variation in this session or a previous session.',
'charging_stations': 'The Charging Station option includes all visitors that have seen the variation in this session or a previous session.',
'contact_ratio': 'The contact ratio option includes all visitors that have seen the variation in this session or a previous session. We will select all available contact metrics for you.',
'contact_ratio_after_order': 'The contact ratio option includes all visitors that have seen the variation in this session or a previous session. We will select all available contact metrics for you. Note that this option will only include contacts related to orders made in this experiment.',
'contact_ratio_self_service': 'This type of test is looking at all visits and has a self service specific way of calculating metrics',
'return_ratio': 'The return ratio option includes all visitors that have seen the variation in this session or a previous session. We will select all available return metrics for you. Use this option if you are optimizing the returns process',
'return_ratio_after_order': 'The return ratio option includes all visitors that have seen the variation in this session or a previous session. We will select all available contact metrics for you. Note that this option will only include returns related to orders made in this experiment.'
}
export const testTypeDatasetsSupport = {'precart': 'You can reference all columns from `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx`.',
'precart_prdt': 'You can reference all columns from `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx`, plus the following fields: laptop_seen, tv_seen, mobile_seen, washing_machine_seen, smart_home_seen and apple_seen.',
'checkout': 'You can reference all columns from `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx`.',
'energy': 'You can reference all columns from `coolblue-webandapp-prod.etl_google_analytics.energy_sessions`.',
'charging_stations': 'You can reference all columns from `coolblue-webandapp-prod.etl_google_analytics.charging_stations_sessions`.',
'contact_ratio': 'In terms of dimensions, you can reference all columns from `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx`. Also we give you the option to select specific contact reasons by ID.',
'contact_ratio_after_order': 'In terms of dimensions, you can reference all columns from `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx`. Also we give you the option to select specific contact reasons by ID.',
'contact_ratio_self_service': 'In terms of dimensions, You can reference all columns from `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx`.',
'return_ratio': 'In terms of dimensions, you can reference all columns from `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx`. Also we give you the option to select specific return reasons by ID.',
'return_ratio_after_order': 'In terms of dimensions, you can reference all columns from `coolblue-webandapp-prod.etl_google_analytics.ga_sessions_20xx`. Also we give you the option to select specific return reasons by ID.'
}
export const metricTypeSupport = {1: 'You can reference all continuous metrics from `coolblue-webandapp-prod.ab_experimenting_etl.ab_fact_margin_per_campaign`. Please note that running continuous metrics can take a few minutes and that it is advised to run one metric at a time.',
0: 'Make sure you select binary metrics / colums since you have selected the binary metrics option.'
}

export default {metricsDefinition, initialDimensions, initialMetrics, testTypeVisitsSupport, testTypeDatasetsSupport, metricTypeSupport}
